/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.jjj{
  color: #30323d;
  color: #4d5061;
  color: #439775;
  color: #46ab79;
  color: #48bf84;
  color: #61d095;
  color: #ffffff;
  color: #1d1d16;
  color: #16161d;
  color: #1b1b1b;
  color:#cc7d2f;
  color: #f1f5f1;
}

*::selection{
  background: #48bf84;
  color: #fff;
}

.hide {
  opacity: 0;
  transition: all 2s;
}

.show {
  opacity: 1;
  transition: all 2s;
}

.moveleft {
  opacity: 1;
  animation: moveleft 2s linear;
}

.moveright {
  opacity: 1;
  animation: moveright 2s linear;
}

.movedown {
  opacity: 1;
  animation: movedown 2s linear;
}


@keyframes moveright {
  0% {
      opacity: 1;
      transform: translateX(-100px);
  }
  100% {
      opacity: 1;
      transform: translateY(0px);
  }
}

@keyframes moveleft {
  0% {
      opacity: 1;
      transform: translateX(100px);
  }
  100% {
      opacity: 1;
      transform: translateY(0px);
  }
}

@keyframes movedown {
  0% {
      opacity: 1;
      transform: translateY(-30px);
  }
  100% {
      opacity: 1;
      transform: translateY(0px);
  }
}